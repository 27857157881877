<template>
  <Head :title="__('chapp.index.title')" />

  <Header
    :can-switch-calendar="true"
  />

  <WeekCalendar
    :trips="trips"
  />
</template>

<script setup>
import {Head} from '@inertiajs/vue3';
import WeekCalendar from '@chapp/js/Components/WeekCalendar.vue';
import {defineProps} from 'vue';
import {useStore} from 'vuex';
import Header from '../../Components/Header.vue';

defineProps({
  trips: {
    type: Object,
    required: true,
  },
});

const $store = useStore();
$store.commit('settings/setWeekCalendar');
</script>
