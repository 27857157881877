<template>
  <div class="absolute w-screen p-2 z-30">
    <div
      v-if="$page.props.flash.success && show"
      class="rounded-md bg-green mt-4 p-4 hover:cursor-pointer"
      @click="show = false"
    >
      <div class="flex items-center">
        <div class="shrink-0">
          <i
            class="fas fa-check-circle w-5 text-white"
            aria-hidden="true"
          />
        </div>

        <div class="ml-3">
          <p class="text-md text-white">
            {{ $page.props.flash.success }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="$page.props.flash.error && show"
      class="rounded-md bg-red mt-4 p-4 hover:cursor-pointer"
      @click="show = false"
    >
      <div class="flex items-center">
        <div class="shrink-0">
          <i
            class="fas fa-times-circle w-5 text-white"
            aria-hidden="true"
          />
        </div>

        <div class="ml-3">
          <p class="text-md text-white">
            {{ $page.props.flash.success }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';

let show = ref(true);
</script>
