const state = () => ({});

const mutations = {
  setForm (state, form) {
    Object.assign(state, form);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
