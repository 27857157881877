<template>
  <div>
    <section class="border-b p-4">
      <div class="container">
        <div class="grid grid-cols-3 align-items-center">
          <div class="text-center text-3xl">
            <a
              class="hover:cursor-pointer p-2"
              @click="previousWeek"
            >
              <i class="fa fa-chevron-left mt-4" />
            </a>
          </div>

          <div class="text-center mt-3">
            <span class="font-bold text-xl">
              {{ __('chapp.calendar.week', {week: selectedDateFormatted}) }}
            </span>
          </div>

          <div class="text-center text-3xl">
            <a
              class="hover:cursor-pointer p-2"
              @click="nextWeek"
            >
              <i class="fa fa-chevron-right mt-4" />
            </a>
          </div>
        </div>
      </div>
    </section>

    <div class="bg-gray-50 relative">
      <div class="grid grid-cols-7 align-items-center ml-16">
        <div
          v-for="day in dayRange()"
          :key="day"
          class="text-center"
        >
          {{ day }}
        </div>
      </div>

      <div
        v-for="(index, hour) in hourRange()"
        :key="hour"
      >
        <p
          class="py-1 font-bold px-4"
          :class="{
            'border': index !== 0,
          }"
          v-text="(hour < 10 ? `0${hour}` : hour) + ':00'"
        />
      </div>

      <div class="absolute top-0 left-0 right-4 h-full mt-6 ml-16">
        <div class="grid grid-rows-[repeat(48,minmax(17px,17px))] grid-cols-7 gap-x-2">
          <Link
            v-for="trip in tripsThisWeek"
            :key="trip.id"
            :href="route('chapp.trips.show', trip.id)"
            class="bg-red opacity-60 text-white p-1 hover:underline hover:text-gray-800 overflow-hidden ml-2"
            :style="{
              gridRowStart: trip.startRow,
              gridRowEnd: trip.endRow,
              gridColumnStart: trip.startColumn,
              gridColumnEnd: trip.endColumn,
            }"
          >
            <p>
              {{ moment(trip.start).format('hh:mm') }}
            </p>
            <p class="font-bold">
              {{ trip.reference }}
            </p>
            <p>
              {{ trip.carrier_name }}, {{ trip.stops_count }} {{ __('chapp.calendar.stops') }}
            </p>
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import moment from 'moment/moment';

const props = defineProps({
  trips: {
    type: Object,
    required: true,
  },
});

const selectedDate = ref(moment());
const selectedDateFormatted = ref(`${selectedDate.value.week()} (${selectedDate.value.format('MMMM')})`);

const tripsThisWeek = ref([]);

const updateTripsThisWeek = () => {
  tripsThisWeek.value = props.trips.filter(trip => {
    const currentWeek = selectedDate.value.week();

    return trip.start_week_number === currentWeek
      || trip.end_week_number === currentWeek
      || (trip.start_week_number < currentWeek && trip.end_week_number > currentWeek);
  });

  updateTripRows();
};

const updateTripRows = () => {
  const tripRows = [];

  tripsThisWeek.value.forEach(trip => {
    if (trip.startRow && trip.endRow) {
      tripRows.push(trip);
      return;
    }

    const start = moment(trip.start);
    const end = moment(trip.end);

    let startHour = start.hour();
    let endHour = end.hour();

    let startMinute = start.minute();
    let endMinute = end.minute();

    if (start.isBefore(selectedDate.value, 'week')) {
      startHour = 0;
      startMinute = 0;
    }

    if (end.isAfter(selectedDate.value, 'week')) {
      endHour = 24;
      endMinute = 0;
    }

    const startRow = Math.round(((startHour * 60 + startMinute) / 60) * 2) + 1;
    const endRow = Math.round(((endHour * 60 + endMinute) / 60) * 2) + 1;

    const tripRow = {
      startRow: startRow,
      endRow: endRow,
      startColumn: trip.start_day_of_week,
      endColumn: trip.end_day_of_week + 1, // due to grid, the end must be +1
      ...trip,
    };

    tripRows.push(tripRow);
  });

  tripsThisWeek.value = tripRows;
};

updateTripsThisWeek();

const hourRange = () => {
  return Array.from(Array(24).keys());
};

const dayRange = () => {
  const currentWeek = selectedDate.value;

  const days = [];
  for (let weekDay = 1; weekDay <= 7; weekDay++) {
    days.push(currentWeek.isoWeekday(weekDay).format('DD-MM'));
  }

  return days;
};

const nextWeek = () => {
  selectedDate.value.add(1, 'week');
  selectedDateFormatted.value = `${selectedDate.value.week()} (${selectedDate.value.format('MMMM')})`;
  updateTripsThisWeek();
};

const previousWeek = () => {
  selectedDate.value.subtract(1, 'week');
  selectedDateFormatted.value = `${selectedDate.value.week()} (${selectedDate.value.format('MMMM')})`;
  updateTripsThisWeek();
};

</script>
