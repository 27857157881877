const state = () => ({
  isDayCalendar: false,
  isWeekCalendar: false,
});

const mutations = {
  setDayCalendar(_state) {
    _state.isDayCalendar = true;
    _state.isWeekCalendar = false;
  },

  setWeekCalendar(_state) {
    _state.isDayCalendar = false;
    _state.isWeekCalendar = true;
  },

  switchCalendarType(_state) {
    if (_state.isDayCalendar) {
      _state.isDayCalendar = false;
      _state.isWeekCalendar = true;
    } else {
      _state.isDayCalendar = true;
      _state.isWeekCalendar = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
