<template>
  <Head :title="stopTitle()" />

  <Header />

  <div class="bg-gray-50">
    <div class="container p-6 relative">
      <Link
        :href="route('chapp.trips.show', currentStop.trip_id)"
        class="absolute top-6 right-6"
      >
        <i
          class="fas fa-times w-5"
          aria-hidden="true"
        />
      </Link>
      <h3 class="text-2xl font-bold">
        {{ __('chapp.stops.activity') }}
      </h3>

      <div class="grid grid-cols-2 gap-x-4 gap-y-1 mt-4 text-lg">
        <p class="font-bold">
          {{ __('chapp.stops.type') }}
        </p>
        <p>
          {{ currentStop.type }} <br> {{ currentStop.eta }}
        </p>

        <p class="font-bold">
          {{ __('chapp.stops.carrier') }}
        </p>
        <p>
          {{ currentStop.carrier_name }}
        </p>

        <p class="font-bold">
          {{ __('chapp.stops.vendor') }}
        </p>
        <p>
          {{ currentStop.vendor_name }}
        </p>

        <p class="font-bold">
          {{ __('chapp.stops.address') }}
        </p>
        <p>
          {{ currentStop.street }} {{ currentStop.house_no }} <br> {{ currentStop.postcode }}
        </p>

        <div
          v-if="currentStop.deviation_code_code && currentStop.completed_at"
          class="col-span-2 grid grid-cols-2"
        >
          <p class="font-bold">
            {{ __('chapp.stops.deviation_code') }}:
          </p>

          <div>
            <p class="text-white bg-red rounded-md px-1 py-0.5 text-sm w-fit font-bold">
              {{ currentStop.deviation_code_code }}
            </p>
            <p>
              {{ deviationCodes.find((deviationCode) => deviationCode.id === currentStop.deviation_code_id).label }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="!currentStop.completed_at && canUpdateStatus"
        class="mt-4"
      >
        <label
          for="location"
          class="block text-xs text-gray-300"
        > {{ __('chapp.stops.deviation_code') }} </label>
        <select
          v-model="currentStop.deviation_code_id"
          class="mt-0.5 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue"
        >
          <option :value="null">
            {{ __('chapp.stops.no_deviation') }}
          </option>
          <option
            v-for="deviationCode in $page.props.deviationCodes"
            :key="deviationCode.id"
            :value="deviationCode.id"
          >
            {{ deviationCode.label }}
          </option>
        </select>
      </div>

      <div class="mt-4">
        <div
          v-if="!currentStop.completed_at && !loading && canUpdateStatus"
          class="bg-red block w-full p-5 text-white text-xl rounded-md text-center hover:cursor-pointer"
          @click="completeStop"
        >
          {{ __('chapp.stops.complete') }}
          <i
            class="fas fa-chevron-right w-5"
            aria-hidden="true"
          />
        </div>
        <div
          v-else-if="currentStop.completed_at && !loading && canUpdateStatus"
          class="bg-red block w-full p-5 text-white text-xl rounded-md text-center hover:cursor-pointer"
          @click="unCompleteStop"
        >
          {{ __('chapp.stops.completed_at') }} {{ currentStop.completed_at }} ({{ __('chapp.stops.un_complete') }})
        </div>
        <div
          v-else-if="loading"
          class="bg-gray-300 block w-full p-5 text-white text-xl rounded-md text-center hover:cursor-not-allowed"
        >
          {{ __('chapp.stops.loading') }}
        </div>
        <div
          v-else
          class="bg-gray-300 block w-full p-5 text-white text-xl rounded-md text-center hover:cursor-not-allowed"
        >
          {{ __('chapp.stops.can_not_update_status') }}
        </div>

        <Link
          :href="route('chapp.trips.show', currentStop.trip_id)"
          class="text-red text-lg mt-6 block text-center"
        >
          {{ __('chapp.stops.back') }}
        </Link>
      </div>

      <div
        v-if="currentStop.completed_at"
        class="mt-8 content-center text-center"
      >
        <i
          class="fa fa-check fa-3x rounded-full p-4 bg-opacity-40"
          :class="{ 'bg-green-300 text-green' : !currentStop.deviation_code_id, 'bg-red-300 text-red' : currentStop.deviation_code_id }"
          aria-hidden="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Header from '@chapp/js/Components/Header.vue';
import {Head} from '@inertiajs/vue3';
import {defineProps, ref} from 'vue';
import axios from 'axios';
import {__} from '@global/js/helpers.js';

const props = defineProps({
  stop: {
    type: Object,
    required: true,
  },

  deviationCodes: {
    type: Object,
    required: true,
  },

  canUpdateStatus: {
    type: Boolean,
    required: true,
  },
});

const currentStop = ref(props.stop);
const loading = ref(false);

const completeStop = () => {
  loading.value = true;

  axios.patch(route('chapp.stops.complete', currentStop.value.id), {
    deviation_code_id: currentStop.value.deviation_code_id,
  }).then((data) => {
    loading.value = false;
    if (data.data.success) {
      currentStop.value.completed_at = data.data.completed_at;
      currentStop.value.deviation_code_code = data.data.deviation_code_code;
    }
  });
};

const unCompleteStop = () => {
  loading.value = true;

  axios.patch(route('chapp.stops.un-complete', currentStop.value.id))
    .then((data) => {
      loading.value = false;
      if (data.data.success) {
        currentStop.value.deviation_code_id = null;
        currentStop.value.deviation_code_code = null;
        currentStop.value.completed_at = null;
      }
    });
};

const stopTitle = () => {
  return props.stop.postcode + ' / ' + props.stop.house_no + ' - ' + props.stop.trip_reference + ' | ' + __('chapp.stops.title');
};
</script>
