import {createStore} from 'vuex';
import VuexPersistence from 'vuex-persist';
import form from '@global/js/store/modules/form';
import translations from '@global/js/store/modules/translations';
import settings from './settings';

const plugins = [];

// fix for SSR; SSR does not have "window", nor any other storage suitable for VueXPersistence.
if (typeof window !== 'undefined') {
  const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: ['settings'],
  });

  plugins.push(vuexLocal.plugin);
}

export default createStore({
  modules: {
    form,
    translations,
    settings,
  },
  plugins: plugins,
});
