<template>
  <div
    v-if="showButton"
    class="z-20 absolute top-4 left-1/2 transform -translate-x-1/2 w-[44px]"
  >
    <a
      href="javascript:void(0);"
      class="absolute z-20 block rounded-full bg-blue-500 hover:bg-blue/90 text-white py-1 px-3 text-center"
      @click="open = true"
    >
      <i class="fa fa-language" />
    </a>
  </div>

  <div
    v-if="open"
    class="relative z-50"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div
        class="flex min-h-full justify-center p-4 items-center"
        @click="close"
      >
        <div
          class="relative transform overflow-hidden rounded-lg bg-white text-left max-w-3xl"
          @click.stop
        >
          <div
            class="absolute top-2 right-4 hover:cursor-pointer"
            @click="close"
          >
            <i class="fa fa-times" />
          </div>

          <div>
            <table
              class="min-w-full divide-y divide-gray-200"
            >
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-4 md:px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                  >
                    Key
                  </th>
                  <th
                    scope="col"
                    class="px-4 md:px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                  >
                    Value
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(value, key) in store.state.translations.used"
                  :key="key"
                  class="even:bg-gray-50 hover:bg-gray-100 focus-within:bg-gray-100"
                >
                  <td class="flex px-4 md:px-6 py-4 text-sm font-medium text-gray-700">
                    <a
                      :href="translationRoute(key)"
                      target="_blank"
                      class="text-blue-700 hover:text-blue-500"
                    >
                      {{ key }}
                    </a>
                  </td>
                  <td
                    class="px-4 md:px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import {router, usePage} from '@inertiajs/vue3';

const showButton = ref(false);
const open = ref(false);

const store = useStore();
const page = usePage();

const close = () => {
  open.value = false;
};

const translationRoute = (key) => {
  return page.props.translations_tracker.url.replace('KEY', key);
};

const clearTranslationsOnNavigate = () => {
  store.commit('translations/clearUsed');
};

const showButtonHandler = (event) => {
  if (event.key === 'i' && event.ctrlKey) {
    showButton.value = !showButton.value;
  }
};

onMounted(() => {
  if (page.props.translations_tracker.track) {
    router.on('finish', clearTranslationsOnNavigate);
    router.on('navigate', clearTranslationsOnNavigate);
    document.addEventListener('keydown', showButtonHandler);
  }
});
</script>
