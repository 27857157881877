const state = () => ({
  used: {},
});

const mutations = {
  addUsed (state, {key, value}) {
    state.used[key] = value;
  },
  clearUsed(state) {
    state.used = {};
  },
};

const actions = {
  addUsed ({commit}, options) {
    commit('addUsed', options);
  },
  clearUsed ({commit}) {
    commit('clearUsed');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
