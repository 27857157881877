import {useI18n} from 'vue-i18n';
import {usePage} from '@inertiajs/vue3';

let i18n = null;
export function __(key, options) {
  if (!i18n) {
    i18n = useI18n();
  }

  return i18n.t(key, options);
}

let page = null;
const _formatMoney = (value) => {
  if (!page) {
    page = usePage();
  }

  return value.toFloat()
    .toLocaleString(page.props.locale, { style: 'currency', currency: 'EUR' });
};

export function formatMoney(value) {
  return _formatMoney(value);
}

export default {
  methods: {
    __(key, options) {
      return this.$t(key, options);
    },
    trans_choice(key, number, options) {
      return this.$t(key, number, options);
    },
    formatMoney(value) {
      return _formatMoney(value);
    },
  },
};
