<template>
  <Head :title="__('chapp.settings.title')" />

  <Header />

  <section>
    <div class="container p-6 relative">
      <Link
        :href="route('chapp.index')"
        class="absolute top-6 right-6"
      >
        <i
          class="fas fa-times w-5"
          aria-hidden="true"
        />
      </Link>

      <h3 class="text-2xl font-bold">
        {{ __('chapp.settings.settings') }}
      </h3>

      <div class="space-y-4 mt-4">
        <div>
          <label
            for="location"
            class="block text-xs text-gray-300"
          >
            {{ __('chapp.settings.language') }}
          </label>

          <select
            v-model="userLanguage"
            class="mt-0.5 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue"
            @change="updateLanguage"
          >
            <option
              v-for="(language, languageId) in $page.props.languages"
              :key="languageId"
              :value="languageId"
            >
              {{ language }}
            </option>
          </select>
        </div>

        <div>
          <label
            for="location"
            class="block text-xs text-gray-300"
          >
            {{ __('chapp.settings.timezone') }}
          </label>

          <select
            id="timezone"
            v-model="userTimezone"
            name="timezone"
            class="mt-0.5 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue"
            @change="updateTimezone"
          >
            <option
              v-for="(timezone, key) in $page.props.timezones"
              :key="key"
              :value="key"
            >
              {{ timezone }}
            </option>
          </select>
        </div>

        <h3 class="text-2xl font-bold">
          {{ __('chapp.settings.change_password') }}
        </h3>

        <form
          class="!mt-1"
          @submit.prevent="$store.state.form.patch(route('chapp.settings.update-password'), {
            onSuccess: () => $store.state.form.reset(),
          })"
        >
          <div class="!mt-1">
            <label
              for="current_password"
              class="block text-xs text-gray-300"
            >
              {{ __('chapp.settings.current_password') }}
            </label>

            <div class="mt-0.5">
              <input
                id="current_password"
                v-model="$store.state.form.current_password"
                type="password"
                name="current_password"
                class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                :class="$page.props.errors.current_password ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-gray-300 focus:border-gray-300'"
                :required="true"
              >

              <p
                v-if="$page.props.errors.current_password"
                class="mt-1 text-sm text-red"
                v-text="$page.props.errors.current_password"
              />
            </div>
          </div>

          <div>
            <label
              for="new_password"
              class="block text-xs text-gray-300 mt-2"
            >
              {{ __('chapp.settings.new_password') }}
            </label>

            <div class="mt-0.5">
              <input
                id="new_password"
                v-model="$store.state.form.new_password"
                type="password"
                name="new_password"
                class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                :class="$page.props.errors.new_password ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-gray-300 focus:border-gray-300'"
                :required="true"
              >

              <p
                v-if="$page.props.errors.new_password"
                class="mt-1 text-sm text-red"
                v-text="$page.props.errors.new_password"
              />
            </div>
          </div>

          <div>
            <label
              for="new_password_confirmation"
              class="block text-xs text-gray-300 mt-2"
            >
              {{ __('chapp.settings.confirm_new_password') }}
            </label>

            <div class="mt-0.5">
              <input
                id="new_password_confirmation"
                v-model="$store.state.form.new_password_confirmation"
                type="password"
                name="new_password_confirmation"
                class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                :class="$page.props.errors.new_password_confirmation ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-gray-300 focus:border-gray-300'"
                :required="true"
              >

              <p
                v-if="$page.props.errors.new_password_confirmation"
                class="mt-1 text-sm text-red"
                v-text="$page.props.errors.new_password_confirmation"
              />
            </div>
          </div>

          <button
            type="submit"
            class="bg-red block w-full p-4 text-white text-xl rounded-md text-center mt-4"
          >
            {{ __('chapp.settings.update_password') }}
            <i
              class="fas fa-chevron-right w-5"
              aria-hidden="true"
            />
          </button>
        </form>
      </div>

      <Link
        :href="route('chapp.logout')"
        class="text-red text-lg mt-6 block text-center"
      >
        {{ __('chapp.settings.logout') }}
      </Link>
    </div>
  </section>
</template>

<script setup>
import {Head, useForm} from '@inertiajs/vue3';
import {useStore} from 'vuex';
import {defineProps, toValue} from 'vue';
import {router} from '@inertiajs/vue3';
import Header from '../../Components/Header.vue';

const props = defineProps({
  auth: {
    type: Object,
    required: true,
  },
});

let userTimezone = toValue(props.auth.user.timezone);
let userLanguage = toValue(props.auth.user.language);

const form = useForm({
  current_password: null,
  new_password: null,
  new_password_confirmation: null,
});

useStore().commit('form/setForm', form);

const updateLanguage = () => {
  router.patch(route('chapp.settings.update-language', userLanguage));
};

const updateTimezone = () => {
  router.patch(route('chapp.settings.update-timezone', {
    timezone: userTimezone,
  }));
};
</script>
