<template>
  <section class="bg-red">
    <div class="container p-4">
      <div class="flex flex-wrap items-center">
        <Link
          v-if="$store.state.settings.isDayCalendar"
          :href="canSwitchCalendar ? route('chapp.index.week') : route('chapp.index.day')"
          class="w-1/4"
        >
          <img
            src="@chapp/assets/week-calendar.svg"
            class="w-12"
          >
        </Link>
        <Link
          v-else-if="$store.state.settings.isWeekCalendar"
          :href="canSwitchCalendar ? route('chapp.index.day') : route('chapp.index.week')"
          class="w-1/4 relative"
        >
          <img
            src="@chapp/assets/day-calendar.svg"
            class="w-12"
          >
          <span
            class="absolute top-4 right-0 w-fit h-full text-red text-lg font-bold"
            :class="{
              'left-5': currentDayOfMonth <= 9,
              'left-3.5': currentDayOfMonth >= 10,
            }"
          >
            {{ currentDayOfMonth }}
          </span>
        </Link>

        <div class="w-2/4">
          <div class="bg-white py-2 px-4 rounded-md  max-w-[150px] mx-auto">
            <img
              src="@chapp/assets/logo.svg"
              class="w-full"
            >
          </div>

          <span class="text-white block text-xs text-center mt-2">
            {{ __('chapp.header.logged_in_as') }}: <strong>{{ $page.props.auth?.user?.name }}</strong>
          </span>
        </div>

        <Link
          :href="route('chapp.settings')"
          class="w-1/4"
        >
          <img
            src="@chapp/assets/settings.svg"
            class="w-12 ml-auto"
          >
        </Link>
      </div>
    </div>
  </section>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import {useStore} from 'vuex';
import moment from 'moment/moment';

defineProps({
  canSwitchCalendar: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const $store = useStore();

let currentDayOfMonth = ref(1);
if ($store.state.settings.isWeekCalendar) {
  currentDayOfMonth = ref(moment().format('D'));
}
</script>
