import {createApp, h} from 'vue';
import {createInertiaApp, Link} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {createI18n} from 'vue-i18n';
import {ZiggyVue} from 'ziggy';
import moment from 'moment/moment';
import I18nOverride from '@global/js/i18nOverride';
import DefaultLayout from './Layouts/Default.vue';
import BugsnagPlugin from '../../global/js/bugsnag';
import '../sass/app.scss';
import store from './store/index';

import helpers from '../../global/js/helpers';
import 'moment/min/locales';

createInertiaApp({
  progress: {
    color: '#FCADB3',
  },
  resolve: name => {
    return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue', {eager: true}))
      .then(page => {
        if (page.default.layout === undefined) {
          page.default.layout = DefaultLayout;
        }
        return page;
      });
  },
  async setup ({el, App, props, plugin}) {
    const createdApp = createApp({render: () => h(App, props)});

    moment.locale(props.initialPage.props.momentLocale);

    createdApp
      .use(store)
      .use(plugin)
      .use(ZiggyVue)
      .use(createI18n({
        legacy: false,
        globalInjection: true,
        locale: props.initialPage.props.i18n.locale,
        fallbackLocale: props.initialPage.props.i18n.fallback_locale,
        messages: {
          [props.initialPage.props.i18n.locale]: props.initialPage.props.i18n.translations,
        },
      }))
      .use(I18nOverride)
      .use(BugsnagPlugin)
      .mixin(helpers)
      .component('Link', Link)
      .mount(el);
  },
});
