import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';

let loadedI18n = false;

export default function(Vue) {
  Vue.mixin({
    created () {
      const store = useStore();
      const i18n = useI18n();

      if (typeof i18n.t === 'function' && !loadedI18n) {
        loadedI18n = true;
        const originalI18nT = i18n.t;

        i18n.t = function (key) {
          let value = originalI18nT.apply(this, arguments);

          store.dispatch('translations/addUsed', {key, value});

          return value;
        };
      }

      if (typeof this.$t === 'function') {
        const originalT = this.$t;

        this.$t = function (key) {
          let value = originalT.apply(this, arguments);

          store.dispatch('translations/addUsed', {key, value});

          return value;
        };
      }
    },
  });
}
