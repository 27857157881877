<template>
  <div>
    <section class="border-b p-4">
      <div class="container">
        <div class="grid grid-cols-3 align-items-center">
          <div class="text-center text-3xl">
            <a
              class="hover:cursor-pointer p-2"
              @click="previousDay"
            >
              <i class="fa fa-chevron-left mt-4" />
            </a>
          </div>

          <div class="text-center">
            <span class="text-gray-300 text-sm">
              <span>
                {{ __(`chapp.calendar.days.${selectedDate.locale('en').format('dddd').toLowerCase()}`) }}
              </span>
              <span
                v-if="selectedDate.isSame(today, 'day')"
                class="ml-1"
              >
                {{ selectedDateFormatted }}
              </span>
            </span>
            <br>
            <span class="font-bold text-xl">
              <span
                v-if="selectedDate.isSame(today, 'day')"
              >
                {{ __('chapp.calendar.today') }}
              </span>
              <span
                v-else
                class="font-bold text-xl"
              >
                {{ selectedDateFormatted }}
              </span>
            </span>
          </div>

          <div class="text-center text-3xl">
            <a
              class="hover:cursor-pointer p-2"
              @click="nextDay"
            >
              <i class="fa fa-chevron-right mt-4" />
            </a>
          </div>
        </div>
      </div>
    </section>

    <div class="bg-gray-50 relative">
      <div
        v-for="hour in hourRange()"
        :key="hour"
      >
        <p
          class="border py-1 font-bold px-4"
          v-text="(hour < 10 ? `0${hour}` : hour) + ':00'"
        />
      </div>

      <div class="absolute top-0 left-16 right-4 h-full">
        <div class="grid grid-rows-[repeat(48,minmax(17px,17px))] gap-x-2">
          <Link
            v-for="trip in tripsToday"
            :key="trip.id"
            :href="route('chapp.trips.show', trip.id)"
            class="bg-red opacity-60 text-white p-1 hover:underline hover:text-gray-800 overflow-hidden"
            :style="{gridRowStart: trip.startRow, gridRowEnd: trip.endRow}"
          >
            <p>
              {{ moment(trip.start).format('hh:mm') }}
            </p>
            <p class="font-bold">
              {{ trip.reference }}
            </p>
            <p>
              {{ trip.carrier_name }}, {{ trip.stops_count }} {{ __('chapp.calendar.stops') }}
            </p>
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import moment from 'moment/moment';
import {usePage} from '@inertiajs/vue3';

const props = defineProps({
  trips: {
    type: Object,
    required: true,
  },
});

const page = usePage();
const selectedDate = ref(moment());
const selectedDateFormatted = ref(selectedDate.value.format(page.props.dateFormat));

const today = ref(moment());

const tripsToday = ref([]);

const updateTripsToday = () => {
  tripsToday.value = props.trips.filter(trip => {
    return moment(trip.start).isSame(selectedDate.value, 'day')
      || moment(trip.end).isSame(selectedDate.value, 'day')
      || (moment(trip.start).isBefore(selectedDate.value, 'day') && moment(trip.end).isAfter(selectedDate.value, 'day'));
  });

  updateTripRows();
};

const updateTripRows = () => {
  const tripRows = [];

  tripsToday.value.forEach(trip => {
    if (trip.startRow && trip.endRow) {
      tripRows.push(trip);
      return;
    }

    const start = moment(trip.start);
    const end = moment(trip.end);

    let startHour = start.hour();
    let endHour = end.hour();

    let startMinute = start.minute();
    let endMinute = end.minute();

    if (start.isBefore(selectedDate.value, 'day')) {
      startHour = 0;
      startMinute = 0;
    }

    if (end.isAfter(selectedDate.value, 'day')) {
      endHour = 24;
      endMinute = 0;
    }

    const startRow = Math.round(((startHour * 60 + startMinute) / 60) * 2) + 1;
    const endRow = Math.round(((endHour * 60 + endMinute) / 60) * 2) + 1;

    const tripRow = {
      startRow,
      endRow,
      ...trip,
    };

    tripRows.push(tripRow);
  });

  tripsToday.value = tripRows;
};

updateTripsToday();

const hourRange = () => {
  return Array.from(Array(24).keys());
};

const nextDay = () => {
  selectedDate.value.add(1, 'day');
  selectedDateFormatted.value = selectedDate.value.format('DD-MM-YYYY');
  updateTripsToday();
};

const previousDay = () => {
  selectedDate.value.subtract(1, 'day');
  selectedDateFormatted.value = selectedDate.value.format('DD-MM-YYYY');
  updateTripsToday();
};

</script>
