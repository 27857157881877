<template>
  <Head :title="__('chapp.login.title')" />

  <section class="flex flex-col min-h-screen relative">
    <div class="min-h-fit flex-1 flex justify-center items-center relative overflow-hidden">
      <div class="container px-4 h-full flex justify-center items-center">
        <!-- Content starts here -->
        <div class=" w-full md:w-3/5 md:mx-auto lg:w-2/5 relative z-20 py-20">
          <div class="bg-white p-8">
            <img
              class="mx-auto h-12 w-auto mb-4"
              src="@chapp/assets/logo.svg"
              :alt="__('chapp.login.title')"
            >

            <div class="mt-6 space-y-3">
              <div>
                <label
                  for="email"
                  class="block text-md text-gray-300"
                >
                  {{ __('chapp.login.email') }}
                </label>
                <div class="mt-0.5">
                  <input
                    id="email"
                    v-model="email"
                    type="email"
                    name="email"
                    class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                    :class="$page.props.errors.email ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-gray-300 focus:border-gray-300'"
                    :required="true"
                  >

                  <p
                    v-if="$page.props.errors.email"
                    class="mt-1 text-sm text-red"
                    v-text="$page.props.errors.email"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-md text-gray-300"
                >
                  {{ __('chapp.login.password') }}
                </label>
                <div class="mt-0.5">
                  <input
                    id="password"
                    v-model="password"
                    type="password"
                    name="password"
                    class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                    :required="true"
                  >
                </div>
              </div>

              <button
                type="submit"
                class="w-full block rounded-md bg-red text-white py-3 text-center hover:bg-red/90"
                @click="submit"
              >
                {{ __('chapp.login.sign_in') }}
              </button>

              <a
                :href="route('chapp.password-reset.index')"
                class="text-red underline hover:no-underline block text-center text-sm"
              >
                {{ __('chapp.login.password_reset') }}
              </a>
            </div>
          </div>
        </div>

        <!-- BG image -->
        <img
          src="@global/assets/media/background.jpg"
          class="absolute z-10 w-auto min-w-full min-h-full max-w-none"
        >
      </div>
    </div>

    <Footer />
  </section>
</template>

<script setup>
import {Head, router} from '@inertiajs/vue3';
import {ref, defineOptions} from 'vue';
import Footer from '@chapp/js/Components/Footer.vue';
import Auth from '../../Layouts/Auth.vue';

defineOptions({
  layout: Auth,
});

let email = ref('');
let password = ref('');

const submit = async () => {
  router.post(route('chapp.login.store', {
    email: email.value,
    password: password.value,
  }));
};
</script>
